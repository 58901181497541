import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: '短链',
    path: '/',
    component: () => import('@/views/CardV4.vue'),
  },
  {
    name: '充值页面2',
    path: '/actv2/:card',
    component: () => import('@/views/CardV2.vue'),
  },
  {
    name: '充值页面3',
    path: '/act/:card',
    component: () => import('@/views/CardV4Socket.vue'),
  },
  {
    name: '充值页面2',
    path: '/:card',
    component: () => import('@/views/CardV3.vue'),
  },
  {
    name: '充值页面',
    path: '/sk/:card',
    component: () => import('@/views/CardV4Socket.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
